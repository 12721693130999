.resultCard {
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 27px;
  padding-top: 40px;
}

.resultCard div {
  padding: 0;
}

.flushLeft {
  justify-content: flex-end;
}

.serifContent {
  font-family: "Noto Serif JP", serif !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.44 !important;
  letter-spacing: normal !important;
}

.serifHeading {
  font-family: "Noto Serif JP", serif !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
}

.serifSubHeading {
  margin-bottom: 15px !important;
}
